import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"
import RSSIcon from "../assets/rss.svg"
import Layout from "../components/Layout"
import BlogItem from "../components/BlogItem"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import moment from "moment"
import Navbar from "../components/Navbar"
import { shuffle } from "lodash"
import SEO from "../components/seo"
import axios from "axios"
import ListItem from "../components/ListItem"
import PostItem from "../components/PostItem"
import { tagNameToLabel } from "../utils/constants"

export default function TagPostList({ data, pageContext: { tag } }) {
  const {
    allAirtable: { edges },
  } = data

  const [email, setEmail] = useState("")
  const [subscribed, setSubscribed] = useState(false)

  const onSubscribe = () => {
    if (email) {
      axios
        .post("https://slashblogs.com/subscribe", {
          email,
          tag: "weeklydigest",
        })
        .then(function (res) {
          setEmail("")
          console.log(res)
          setSubscribed(true)
        })
        .catch(function (err) {
          console.error(err)
        })
    }
  }

  return (
    <Layout>
      <SEO
        title={`${tagNameToLabel(tag)} Blog Posts`}
        description={`Latest blog posts from ${tagNameToLabel(
          tag
        )} blogs by individuals.`}
      />

      <div
        css={css`
          margin-bottom: 80px;
        `}
      >
        <div
          css={css`
            text-align: center;
            margin-bottom: 20px;
            margin-top: 40px;
          `}
        >
          <h1
            css={css`
              margin-bottom: 10px;
            `}
          >
            Latest Posts From {tagNameToLabel(tag)} Blogs
          </h1>
          <span
            css={css`
              display: block;
              margin-bottom: 10px;
              font-size: 18px;
              a {
                color: #0178bd;
              }
            `}
          >
            <Link to={`/tag/${tag.replace(".", "dot").replace(/ /g, "-")}`}>
              View Blogs
            </Link>
          </span>
        </div>
        <div
          css={css`
            margin: 0 auto;
            max-width: 800px;
          `}
        >
          {edges.map((edge, i) => {
            const {
              node: { data },
            } = edge
            return <PostItem data={data} index={i} />
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query TagPostListPageQuery($tag: String!) {
    allAirtable(
      filter: {
        data: {
          Flag: { ne: true }
          RSS: { ne: "no" }
          Tags: { eq: $tag }
          Last_Post_Date: { ne: null }
          dkb_review: { eq: "approved" }
        }
        table: { eq: "main" }
      }
      sort: { order: DESC, fields: data___Last_Post_Timestamp }
    ) {
      totalCount
      edges {
        node {
          data {
            Name
            URL
            RSS
            Flag
            NotCodingSpecific
            Last_Post_Date
            Last_Post_Title
            Last_Post_URL
            Tags
          }
        }
      }
    }
  }
`
